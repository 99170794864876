@mixin flex_center($justify, $align) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}

@mixin after_before($width: 100%, $height: 100%) {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: $width;
    height: $height;
}

@mixin align_center($alignment) {
    display: flex;
    justify-content: $alignment;
    align-items: center;
    height: 100%;
}

@mixin list_item($gap: true) {
    li {
        display: inline-block;

        @if $gap == true {
            margin: 0 10px;
        } @else {
            margin: 0 $gap + px;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        a {
            @content;
        }

        button {
            @content;
        }

        div {
            @content;
        }
    }
}

@mixin blur_shape($width, $height, $background, $opacity) {
    .banner_shape {
        position: absolute;
        top: 50%;
        left: 50%;
        width: $width + px;
        height: $height + px;
        background: $background;
        z-index: -1;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        filter: blur(80px);
        opacity: $opacity;
    }
}

@mixin blur_shape2($top, $left, $width, $height, $background, $opacity) {
    .banner_shape2 {
        position: absolute;
        top: $top;
        left: $left;
        width: $width + px;
        height: $height + px;
        background: $background;
        z-index: -1;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        filter: blur(80px);
        opacity: $opacity;
    }
}

@mixin full_height_sec {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

@mixin back_img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
