@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");

$body_font: "Poppins", sans-serif;
$title_font: "Poppins", sans-serif;

$breakpoints: (
    small: 480px,
    medium: 992px,
    large: 1400px,
);

$p_font_sizes: (
    null: (
        15px,
        1.3,
    ),
    small: (
        16px,
        1.4,
    ),
    large: (
        18px,
        2,
    ),
);

$big_p_font_size: (
    null: 16px,
    small: (
        18px,
        1.4,
    ),
    large: (
        22px,
        2.2,
    ),
);

$small_p_font_size: (
    null: 14px,
    small: (
        15px,
        1.4,
    ),
    large: (
        14px,
        1.9,
    ),
);

$h1_font_sizes: (
    null: 28px,
    small: 31px,
    medium: 33px,
    large: (
        110px,
        1.4,
    ),
);

$h2_font_sizes: (
    null: 28px,
    small: 31px,
    medium: 33px,
    large: (
        42px,
        1.2,
    ),
);

$h3_font_sizes: (
    null: 26px,
    small: 28px,
    medium: 34px,
    large: (
        38px,
        1.25,
    ),
);

$h4_font_sizes: (
    null: 24px,
    small: 26px,
    medium: 28px,
    large: (
        30px,
        1.1,
    ),
);

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
    @each $fs-breakpoint, $fs-font-size in $fs-map {
        @if $fs-breakpoint == null {
            @include make-font-size($fs-font-size);
        } @else {
            // If $fs-font-size is a key that exists in
            // $fs-breakpoints, use the value
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }
            @media screen and (min-width: $fs-breakpoint) {
                @include make-font-size($fs-font-size);
            }
        }
    }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
    // If $fs-font-size is a list, include
    // both font-size and line-height
    @if type-of($fs-font-size) == "list" {
        font-size: nth($fs-font-size, 1);
        @if (length($fs-font-size) > 1) {
            line-height: nth($fs-font-size, 2);
        }
    } @else {
        font-size: $fs-font-size;
    }
}
