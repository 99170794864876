$primary_color: #8651d6;
$text_color: #ffffff;
$yellow_color: #fcea76;
$black_color: #282f2a;
$dark_color: #1a2234;
$dark_section_bg: #1f1f20;
$silver_color: #a2a2a2;
$white_color: #ffffff;
$body_bg: #161616;
$red_color: #ce0900;
$main_content_bg: linear-gradient(to right, rgba(255, 255, 255, 0.02) 60%, rgba(0, 0, 0, 0) 100%);
$border_color: rgba($white_color, 0.08);
$input_color: rgba($white_color, 0.5);
$white_input_color: rgba($black_color, 0.2);
$gradient_color: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
