@import "_variables.scss";
@import "_themeColors.scss";
@import "_font.scss";
@import "_mixin.scss";
@import "_space.scss";

/*==========Common CSS ==========*/

:root {
  --header_height: auto;
  --sideBar_width: 300px;
  --sm_sideBar_width: 80px;
  --xs_sideBar_width: 250px;
  --body_space_x: 30px;
  --body_space_y: 20px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  &::-webkit-scrollbar {
    width: 8px;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 3px;

  @media (max-width: 575px) {
    width: 2px;
    height: 2px;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $white_color;
  border-radius: 10px;
}

body {
  font-family: $body_font;
  color: $text_color;
  background-color: $body_bg;
  text-rendering: optimizeSpeed;
}

button,
input,
select,
optgroup,
textarea {
  color: $text_color;
  font-size: 16px;
  line-height: 26px;
}

img {
  max-width: 100%;
}

section {
  position: relative;
}

.sec_wp {
  position: relative;
  z-index: 4;
}

.wow {
  visibility: hidden;
}

.for_des {
  display: block !important;

  @media (max-width: 1199px) {
    display: none !important;
  }
}

.for_mob {
  display: none !important;

  @media (max-width: 1199px) {
    display: block !important;
  }
}

.for_des_flex {
  display: flex !important;

  @media (max-width: 1199px) {
    display: none !important;
  }
}

.for_mob_flex {
  display: none !important;

  @media (max-width: 1199px) {
    display: flex !important;
  }
}

.back_img {
  @include back_img;
}

.dark_section {
  background-color: $dark_section_bg;
  color: $white_color;

  &:after {
    @include after_before();
    background: linear-gradient(to right, $white_color 0%, $black_color 80%);
    opacity: 0.02;
  }
}

.light_bg_sec {
  background-color: rgba($primary_color, 0.1);
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background: $border_color;
}

.table-responsive {
  border-radius: 15px;
  border: 1px solid $border_color;
}

.table {
  margin: 0;
  --bs-table-bg: rgba(225, 225, 225, 0.02);

  > :not(caption)>*>* {
    padding: 1rem 1.5rem;

    @media (max-width: 991px) {
      padding: 0.8rem 0.8rem;
      white-space: nowrap;
    }
  }

  > :not(:first-child) {
    border-color: $border_color;
  }
}

.table_loader_wp {
  position: relative;

  header:empty {
    display: none;
  }

  >div {
    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $white_color;
      border-radius: 10px;
    }
  }

  .table_loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($dark_section_bg, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 3;
    border: 1px solid $border_color;

    svg {
      animation: spin 1.8s linear 0s infinite;

      @keyframes spin {
        from {
          transform: rotate(0);
        }

        to {
          transform: rotate(359deg);
        }
      }
    }
  }
}

.table_loader {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    animation: spin 1.8s linear 0s infinite;

    @keyframes spin {
      from {
        transform: rotate(0);
      }

      to {
        transform: rotate(359deg);
      }
    }
  }
}

.date_picker_wrapper {
  position: relative;
  z-index: 5;

  .react-datepicker-wrapper {
    width: 100%;
  }
}

/*==========Common CSS End==========*/

/*==========Form CSS==========*/

.form_element {
  --padding: 50px;
  position: relative;

  input {
    padding-right: var(--padding);
  }

  i {
    position: absolute;
    top: 15px;
    right: 20px;
    pointer-events: none;
    color: $primary_color;
  }
}

.form_box_wp {
  position: relative;
  padding: 30px;
  border: 1px solid $border_color;
  border-radius: 10px;
  background-color: rgba($white_color, 0.03);

  @media (max-width: 991px) {
    padding: 25px 15px;
  }

  .form_input_wp {
    margin-bottom: 20px;
  }

  &.casino_chart {
    padding-bottom: 0;

    .datepicker_right {
      text-align: right;

      @media (max-width: 991px) {
        text-align: left;
        margin-top: 20px;
      }
    }
  }
}

.form_btn {
  margin-top: 20px;
  text-align: right;
}

.form_remove_btn {
  --size: 40px;
  position: absolute;
  top: -10px;
  right: -10px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 1px solid $red_color;
  background-color: $red_color;
  transition: 0.3s;

  &:hover {
    background-color: $white_color;
    color: $red_color;
  }
}

.form_box {
  .divider {
    &:last-of-type {
      display: none;
    }
  }
}

.form_title {
  margin-bottom: 20px;
}

.form_input_label {
  margin-bottom: 10px;
  display: flex;

  .form_input_icon {
    display: inline-flex;
    margin-right: 6px;
    color: $primary_color;
    margin-top: 1px;
  }

  .form_input_icon svg path {
    stroke: $primary_color;
  }
}

.form_input_wp {
  display: block;
  margin-bottom: 15px;
  position: relative;
}

.checkbox_wp {
  margin-bottom: 15px;

  label {
    vertical-align: super;
    cursor: pointer;
  }
}

.brand_checkbox_wp {
  display: flex;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.form_input::-webkit-input-placeholder {
  color: $input_color;
}

.form_input::-moz-placeholder {
  color: $input_color;
}

.form_input:-ms-input-placeholder {
  color: $input_color;
}

.form_input::-ms-input-placeholder {
  color: $input_color;
}

.form_input::placeholder {
  color: $input_color;
}

select {
  color: $input_color !important;
  -moz-appearance: none;
  -webkit-appearance: none;

  &::-ms-expand {
    display: none;
  }

  option {
    color: $black_color !important;
  }
}

.form_input,
select,
.rs-picker-toggle-textbox {
  width: 100%;
  height: 55px;
  border: 2px solid rgba($silver_color, 0.2);
  outline: none !important;
  padding: 0 15px;
  color: $white_color;
  background: transparent;
  -webkit-appearance: none;
  border-radius: 10px;
  font-weight: 500;

  &.error_input {
    border-color: rgba($red_color, 0.4);
  }

  &[readonly] {
    background: rgba($silver_color, 0.1);
  }
}

textarea.form_input {
  height: 150px;
  resize: none;
  padding: 15px;
  display: block;
}

input[type="checkbox"]:not(.form-check-input),
input[type="radio"]:not(.form-check-input) {
  --size: 24px;
  width: var(--size);
  height: var(--size);
  margin: 0;
  outline: none !important;
  overflow: hidden;
  background: transparent;
  border: 2px solid rgba($silver_color, 0.3);
  box-shadow: none;
  margin-right: 10px;
  -webkit-appearance: none;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

input[type="radio"] {
  border-radius: 50%;
}

input[type="checkbox"]:checked::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4wMzAzIDAuOTY5NjdDMTMuMzIzMiAxLjI2MjU2IDEzLjMyMzIgMS43Mzc0NCAxMy4wMzAzIDIuMDMwMzNMNi4wMzAzMyA5LjAzMDMzQzUuNzM3NDQgOS4zMjMyMiA1LjI2MjU2IDkuMzIzMjIgNC45Njk2NyA5LjAzMDMzTDAuOTY5NjcgNS4wMzAzM0MwLjY3Njc3NyA0LjczNzQ0IDAuNjc2Nzc3IDQuMjYyNTYgMC45Njk2NyAzLjk2OTY3QzEuMjYyNTYgMy42NzY3OCAxLjczNzQ0IDMuNjc2NzggMi4wMzAzMyAzLjk2OTY3TDUuNSA3LjQzOTM0TDExLjk2OTcgMC45Njk2N0MxMi4yNjI2IDAuNjc2Nzc3IDEyLjczNzQgMC42NzY3NzcgMTMuMDMwMyAwLjk2OTY3WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==");
  font-size: 12px;
  padding-top: 2px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flex_center(center, center);
  filter: brightness(1) invert(1);
}

input[type="radio"]:checked::before {
  @include after_before();
  border-radius: 50%;
  background: $primary_color;
  border: 3px solid $white_color;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.form_contact {
  p {
    margin: 0;

    a {
      color: $primary_color;
    }
  }
}

.form_submit {
  svg {
    margin-left: 10px;
    animation: spin 1.8s linear 0s infinite;
  }
}

.form_submit_error,
.error_msg {
  color: $red_color;
}

.success-msg {
  color: #1ebf34;
}

/*==========Form CSS End==========*/

/*==========Typography CSS==========*/

p {
  @include font-size($p_font_sizes);
  color: $silver_color;

  a {
    color: $white_color;
    text-decoration: underline !important;

    &:hover {
      color: $primary_color;
    }
  }

  &.big_text {
    @include font-size($big_p_font_size);
  }

  &.small_text {
    @include font-size($small_p_font_size);
  }

  &:empty {
    display: none;
  }
}

a {
  text-decoration: none !important;
  outline: none !important;
  color: $white_color;

  &:hover {
    color: $primary_color;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  font-family: $title_font;
}

.h1_title,
.h2_title,
.h3_title,
.h4_title,
.h5_title,
.h6_title {
  span {
    display: block;
  }
}

.h1_title {
  @include font-size($h1_font_sizes);
  font-weight: bold;
}

.h2_title {
  @include font-size($h2_font_sizes);
  text-transform: capitalize;
  margin-bottom: 15px;
  position: relative;
  color: $primary_color;
}

.h3_title {
  @include font-size($h3_font_sizes);
}

.h4_title {
  @include font-size($h4_font_sizes);
}

.h5_title {
  font-size: 18px;
  line-height: 28px;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*==========Typography CSS End==========*/

/*==========Button CSS==========*/

.sec_btn {
  --x: 13px;
  --y: 18px;
  --gap: 8px;
  padding: var(--x) var(--y);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $primary_color;
  color: $white_color;
  border: 1px solid $primary_color;
  position: relative;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 1.42857143;
  z-index: 2;
  border-radius: 10px;
  outline: none !important;
  font-weight: 500;
  transition: 0.4s;
  column-gap: var(--gap);

  &:hover {
    background-color: $white_color;
    color: $black_color;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    width: 50px;
    height: 50px;
    transform: translate(0, -50%);
    background: $white_color;
    border-radius: 50%;
    z-index: -1;
    filter: blur(25px);
    opacity: 0.25;
  }

  &.outline_btn {
    background-color: transparent;

    &:hover {
      background-color: $white_color;
      border-color: $white_color;
    }
  }

  &.secondary_btn {
    background-color: rgba($white_color, 0.06);
    border-color: transparent;
    justify-content: space-between;

    &:hover {
      background-color: $primary_color;
      color: $white_color;
    }
  }

  &.transparent_btn {
    background-color: transparent;
    border-color: transparent;
    padding: 0;

    &:hover {
      background-color: transparent;
      color: $white_color;
      opacity: 0.6;
    }

    &:after {
      display: none;
    }
  }

  &.white-hover-btn {
    &:before {
      background: $white_color;
    }

    &:hover {
      color: $black_color;
    }
  }

  &.white-btn {
    background-color: $white_color;
    color: $black_color;
    border-color: $white_color;

    &:hover {
      color: $primary_color;
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.button_group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/*==========Button CSS End==========*/

/*==========White text CSS==========*/

.white_text,
.dark_section {

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul li,
  label,
  .read_more {
    color: $white_color;
  }
}

.white_list_link a {
  color: $white_color;
}

.white_arrow_dots {
  .slick-dots li button {
    border-color: $white_color;
  }

  .slick-dots li.slick-active button,
  .slick-dots li button:hover {
    background: $white_color;
  }

  .slick-arrow {
    background: $white_color;
    color: $black_color;
  }

  .slick-arrow:hover {
    background: $black_color;
    color: $white_color;
  }
}

.white_btn {
  background: $white_color;
  color: $black_color;
  border-color: $white_color;
}

.white_form {
  background: rgba($white_color, 0.95);
  color: $dark_color;

  .form_input,
  select {
    background: transparent;
    color: $dark_color;
  }

  input[type="checkbox"],
  input[type="radio"] {
    background: transparent;

    &:before {
      filter: brightness(1) invert(0);
    }
  }

  .form_input::-webkit-input-placeholder {
    color: $white_input_color;
  }

  .form_input::-moz-placeholder {
    color: $white_input_color;
  }

  .form_input:-ms-input-placeholder {
    color: $white_input_color;
  }

  .form_input::-ms-input-placeholder {
    color: $white_input_color;
  }

  .form_input::placeholder {
    color: $white_input_color;
  }

  select {
    color: $white_input_color !important;
  }

  .form-element {
    i {
      color: $white_color;
    }
  }
}

/*==========White text CSS End==========*/

/*==========loader CSS==========*/

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $black_color;
  z-index: 99;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white_color;
  }
}

/*==========loader CSS End==========*/

/*==========LogIn Page CSS==========*/

.login_form_sec {
  @include full_height_sec;
  padding: 30px 0;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    bottom: 2%;
    right: 18%;
    width: 550px;
    height: 550px;
    background: #2084e0;
    display: block;
    border-radius: 50%;
    filter: blur(150px);
    opacity: 0.4;
    z-index: -1;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -75px;
    right: 0%;
    width: 530px;
    height: 530px;
    background: #e0aa20;
    display: block;
    border-radius: 50%;
    filter: blur(150px);
    opacity: 0.3;
    z-index: -1;
  }

  .form_shape {
    &:after {
      content: "";
      position: absolute;
      top: 2%;
      left: 18%;
      width: 550px;
      height: 550px;
      background: #11e7cb;
      display: block;
      border-radius: 50%;
      filter: blur(200px);
      opacity: 0.18;
      z-index: -1;
    }

    &:before {
      content: "";
      position: absolute;
      top: -10%;
      left: -10%;
      width: 550px;
      height: 550px;
      background: #ffffff;
      display: block;
      border-radius: 50%;
      filter: blur(250px);
      opacity: 0.05;
      z-index: -1;
    }
  }
}

.login_form {
  padding: 40px;
  border-radius: 20px;

  @media (max-width: 575px) {
    padding: 30px;
  }

  @media (max-width: 400px) {
    padding: 25px 15px;
  }
}

/*==========LogIn Page CSS End==========*/

/*==========Page Layout Page CSS==========*/

.page_wrapper {
  display: flex;
  min-height: 100vh;
  height: auto;
}

.main_content {
  padding: var(--body_space_y) var(--body_space_x);
  width: calc(100% - var(--sideBar_width));
  margin-left: auto;
  background: $main_content_bg;
  padding-bottom: 40px;

  @media (max-width: 1199px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    --body_space_x: 15px;
    --body_space_y: 15px;
  }
}

/*==========Page Layout Page CSS End==========*/

/*==========Side Bar Page CSS==========*/

.sidebar_overlay {
  @media (max-width: 1199px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $body_bg;
    z-index: 100;
    transform: scaleX(0);
    transition: 0.3s;
    transform-origin: left;
    opacity: 0.5;
  }
}

.sidebar {
  width: var(--sideBar_width);
  padding: var(--body_space_y) 0;
  border-right: 1px solid $border_color;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  .sidebar_top {
    padding: 8px var(--body_space_x) 0;
  }

  .site_logo {
    display: inline-flex;

    img {
      max-height: 60px;
    }
  }

  .sidebar_menulist {
    margin-top: 20px;

    ul {
      li {
        display: block;
        padding: 5px var(--body_space_x);

        &.menu_item_loader {
          span {
            display: block;
            height: 46px;
            border-radius: 5px;
          }

          @for $i from 1 through 10 {
            $lightness: (
              100 / ($i * 5)) / 100;

            &:nth-of-type(#{$i}) {
              span {
                background-color: rgba($white_color, $lightness
                );
            }
          }
        }
      }

      a {
        display: block;
        position: relative;
        font-weight: 500;
        text-transform: capitalize;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: calc(var(--body_space_x) * -1);
          width: 5px;
          height: 50%;
          transform: translate(0, -50%);
          background-color: $primary_color;
          border-radius: 0 5px 5px 0;
          transition: 0.3s;
          opacity: 0;
        }

        span {
          display: flex;
          align-items: center;
          column-gap: 12px;
          padding: 10px 14px;
          border-radius: 8px;
          background-color: transparent;
          border: 1px solid $border_color;
          transition: 0.3s;

          >div {
            display: flex;
            justify-content: flex-start;
            overflow: hidden;
            flex-direction: column;

            small {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              position: relative;
              font-size: 12px;
              font-style: italic;
              color: $white_color;
              opacity: 0.4;
            }
          }

          svg {
            flex: 0 0 auto;
          }

          .dropdown_arrow {
            margin-left: auto;
            transition: 0.3s;
          }
        }

        &:hover,
        &.isActive {
          color: $primary_color;

          &:after {
            height: 70%;
            opacity: 1;
          }

          span {
            background-color: rgba($white_color, 0.01);
          }
        }
      }

      &.submenu {
        &.active_submenu {
          .submenu_wrapper {
            grid-template-rows: 1fr;

            ul {
              border-radius: 0 0 5px 5px;
            }
          }

          >a {
            span {
              border-radius: 5px 5px 0 0;
              border-bottom: 0;

              .dropdown_arrow {
                transform: rotate(-180deg);
              }
            }
          }
        }

        .submenu_wrapper {
          display: grid;
          grid-template-rows: 0fr;
          transition: 0.3s;

          div {
            overflow: hidden;
          }

          ul {
            padding: 5px;
            border: 1px solid rgba($white_color, 0.08);
            border-radius: 5px;

            li {
              padding: 5px;

              a {
                padding: 0;

                &:after {
                  left: -10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  transform: translate(calc(var(--sideBar_width) * -1));
  --body_space_x: 20px;
  width: var(--sideBar_width) !important;
  background-color: $body_bg;
  z-index: 100;
  transition: 0.3s;
}

@media (max-width: 480px) {
  width: var(--xs_sideBar_width) !important;
}
}

.sidebar_collapsed {
  .sidebar_overlay {
    transform: scaleX(1);
  }

  .sidebar_top {
    text-align: center;
  }

  .sidebar {
    --body_space_x: 14px;
    width: var(--sm_sideBar_width);

    ul {
      li {
        a {
          span {
            padding: 10px 8px;
            justify-content: center;
          }
        }
      }
    }
  }

  .sidebar_collapse_button {
    left: calc(var(--sm_sideBar_width) - 20px);

    @media (max-width: 1199px) {
      left: calc(var(--sideBar_width) - 20px);
    }

    @media (max-width: 480px) {
      left: calc(var(--xs_sideBar_width) - 20px);
    }
  }

  .main_content {
    width: calc(100% - var(--sm_sideBar_width));

    @media (max-width: 1199px) {
      width: 100%;
    }
  }

  @media (max-width: 1199px) {
    .sidebar {
      transform: translate(0, 0);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 100%;
        width: 100vw;
        height: 100%;
        background: #000;
        opacity: 0.7;
      }

      ul {
        li {
          a {
            span {
              justify-content: start;
            }
          }
        }
      }
    }
  }
}

/*==========Side Bar Page CSS End==========*/

/*==========Header CSS==========*/

.sidebar_collapse_button {
  position: fixed;
  left: calc(var(--sideBar_width) - 20px);
  top: 32px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid $border_color;
  background: $body_bg;
  padding-right: 1px;
  transition: 0.3s;
  z-index: 101;

  &:hover {
    background: $primary_color;
  }

  @media (max-width: 1199px) {
    left: -40px;
  }
}

.site_header {
  // position: sticky;
  // top: 15px;
  // left: 0;
  z-index: 99;
  height: var(--header_height);
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid $border_color;
  position: relative;

  .header_menu {
    @include align_center(center);

    ul {
      @include list_item() {
        color: $black_color;
        font-weight: 500;
        transition: 0.3s;
        position: relative;
        @include flex_center(unset, center);
        text-transform: capitalize;

        &:before {
          content: "";
          width: 6px;
          height: 6px;
          background: $primary_color;
          border-radius: 50%;
          display: inline-block;
          margin-right: 5px;
          transition: 0.3s;
        }

        &:hover {
          color: $primary_color;
          opacity: 1;

          &:before {
            background: $black_color;
            opacity: 0.6;
          }
        }
      }
    }
  }

  .main_header_content {
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    column-gap: 15px;

    h5 {
      flex: 1;
      width: 100%;
    }

    @media (max-width: 1199px) {
      flex-direction: column-reverse;
    }
  }

  .header_right {
    margin-left: auto;

    ul {
      .dropdown_user_profile {
        .dropdown_list {
          li {
            --mb: 0;
            border: none;
            background-image: none;
            margin-bottom: 10px;

            a {
              display: block;
              width: 100%;
            }
          }
        }
      }

      &:not(.dropdown_list) {
        display: flex;
        align-items: center;
        column-gap: 20px;

        @media (max-width: 1199px) {
          flex-direction: column;
          row-gap: 15px;
        }
      }

      li {
        display: inline-block;

        .active_dropdown {
          .dropdown_list {
            margin-top: 5px;
            visibility: visible;
            opacity: 1;
            transition: 0.1s;
          }
        }

        .dropdown_list {
          position: absolute;
          top: auto;
          right: 0;
          width: 100%;
          transition: 0.1s;
          margin-top: 10px;
          visibility: hidden;
          opacity: 0;
          z-index: 10;
          background-color: rgba($white_color, 0.04);
          backdrop-filter: blur(80px);
          padding: 15px 20px;
          border-radius: 10px;
          min-width: 270px;
          max-height: 380px;
          overflow-x: auto;

          &:hover {
            margin-top: 5px;
            visibility: visible;
            opacity: 1;
            transition: 0.1s;
          }
        }

        @media (max-width: 575px) {
          display: block;
          width: 100%;
          text-align: center;

          .dropdown_user_profile {
            .dropdown_list {
              max-width: 310px;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }

          .dropdown_list {
            li {
              text-align: left;
            }
          }
        }
      }
    }

    @media (max-width: 1199px) {
      margin: 0 auto;
      padding-bottom: 40px;
      display: none;
      padding-top: 15px;
      border-top: 1px solid $border_color;
      width: 100%;

      &.player_open {
        display: block;
      }
    }
  }

  .dropdown_user_profile_img {

    // margin-right: var(--x);
    img {
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      box-shadow: 2px 2px 40px rgba($white_color, 0.25);
      filter: brightness(1.2);
      min-width: 42px;
    }
  }

  @media (max-width: 1199px) {
    display: block;

    .top_header {
      margin-bottom: 20px;
      position: relative;

      .menu_toggle {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        background: transparent;
        border: none;
        outline: none;
        z-index: 101;
      }

      .player_toggle {
        right: 40px;
        z-index: 2;
      }

      @media (max-width: 480px) {
        .site_logo {
          img {
            width: 160px;
            height: auto;
          }
        }
      }
    }

    h5 {
      border-top: 1px solid $border_color;
      padding-top: 12px;
    }
  }
}

.dropdown_wp {
  position: relative;

  &.active_dropdown {
    .dropdown_btn {
      .dropdown_icon {
        transform: rotate(180deg);
      }
    }

    .dropdown_content {
      margin-top: 5px;
      visibility: visible;
      opacity: 1;
      transition: 0.1s;
    }
  }

  .dropdown_btn {
    --x: 10px;
    --y: 20px;
    --gap: 10px;

    b {
      font-weight: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .dropdown_icon {
      --size: 26px;
      background-color: rgba($white_color, 0.08);
      padding: 2px;
      border-radius: 50%;
      width: var(--size);
      height: var(--size);
      transition: 0.3s;
    }

    .dropdown_user_profile_title {
      display: flex;
      gap: 8px;

      strong {
        @media (max-width: 1400px) {
          max-width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      span {
        display: flex;
        text-align: left;
        align-items: center;

        @media (max-width: 575px) {
          display: none;
        }

        svg {
          margin-right: 5px;
        }
      }
    }

    &:after {
      display: none;
    }

    @media (max-width: 480px) {
      .dropdown_user_profile_title {
        width: clamp(120px, 51vw, 230px);

        strong {
          max-width: 100%;
        }
      }
    }
  }

  .dropdown_search {
    position: relative;
    margin-bottom: 10px;

    .form_input {
      padding-left: 45px;
      height: 45px;
      background: rgba($body_bg, 0.6);
    }

    svg {
      width: 22px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translate(0, -50%);
      opacity: 0.3;
    }
  }

  .dropdown_content {
    position: absolute;
    top: auto;
    right: 0;
    width: 100%;
    transition: 0.1s;
    margin-top: 10px;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    background-color: rgba($white_color, 0.04);
    backdrop-filter: blur(80px);
    padding: 15px;
    border-radius: 10px;
    min-width: 170px;
    max-height: 380px;
    overflow-x: auto;
  }

  .dropdown_list {
    text-align: left;
    margin-top: 5px;

    li {
      --mb: 8px;
      display: block !important;
      cursor: pointer;
      transition: 0.3s;
      padding: var(--mb) 14px;
      border-left: 2px solid transparent;

      @media (max-width: 575px) {
        --mb: 5px;
        padding: var(--mb) 9px;
      }

      a {
        display: block;
        width: 100%;
      }

      svg {
        margin-right: 5px;
      }

      .divider {
        margin-bottom: 10px;
      }

      &.active-option,
      &:hover {
        color: $white_color;
        border-color: rgba(255 255 255 / 30%);
        background-image: linear-gradient(90deg,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0) 80%);
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &.dropdown_open_top {
    .dropdown_content {
      bottom: 100%;
      margin-bottom: 5px;
      margin-top: 0;
    }
  }
}

/*==========Header CSS End==========*/

/*==========Home Page CSS End==========*/

.dashboard_form {
  .form_input_label {
    @media (max-width: 1400px) {
      font-size: 14px;

      .form_input_icon {
        display: none;
      }
    }
  }

  .dropdown_home_brand {
    width: 40%;

    @media (max-width: 575px) {
      width: 100%;
    }
  }
}

/*==========Home Page CSS End==========*/

/*==========Casino Page CSS End==========*/
.react-datepicker-popper {
  z-index: 4;
}

.react-datepicker,
.daterangepicker {
  background-color: rgba($black_color, 0.8);
  border-radius: 20px;
  border: 2px solid rgba($white_color, 0.1);
  backdrop-filter: blur(20px);

  .react-datepicker__triangle {
    transform: translate(25px, 0) !important;
    opacity: 0.4;
  }

  select.monthselect,
  select.yearselect {
    padding: 6px 0px 6px 8px;
    -webkit-appearance: auto;
    color: $white_color !important;
    border-radius: 6px !important;
  }

  .react-datepicker__header {
    padding: 10px;
    border-radius: 20px 20px 40px 40px;
    background-color: rgba($black_color, 0.3);
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header,
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: $white_color;
  }

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text,
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    border-radius: 50%;
    border: 2px solid transparent;
    width: 35px;
    height: 35px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    color: $white_color;

    &:empty {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    top: 11px;
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range) {
    background-color: transparent;
    border-color: $primary_color;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: $primary_color;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: $black_color;
  }
}

.date_picker_wp {
  position: relative;

  .date_picker_close_icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    border: unset;
    background: transparent;
    outline: unset;
  }
}

.daterangepicker .calendar-table {
  background: transparent;
  border: none;
}

.currency_dropdown {
  .transparent_btn {
    width: 100%;
    justify-content: space-between;
    height: 55px;
    border: 2px solid rgba(162, 162, 162, 0.2);
    padding: 10px 20px;
    text-align: left;

    span {
      margin-left: 10px;
    }
  }

  .dropdown_list {
    // height: 45vh;
    overflow-y: auto;
    // width: max-content;
    // left: 0;
  }
}

.revenue_box_content {
  padding: 30px;
  background-color: rgba($white_color, 0.02);
  border-radius: 15px;
  border: 1px solid $border_color;

  .h5_title {
    font-weight: 500;
    margin-bottom: 5px;
  }

  .h4_title {
    margin: 10px 0;
    color: $primary_color;

    @media (max-width: 1500px) {
      font-size: 22px;
      margin: 10px 0;
    }
  }

  .revenue_box_usd {
    color: $white_color;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    opacity: 0.7;
  }

  p {
    line-height: 1;

    @media (max-width: 1500px) {
      font-size: 16px;
      line-height: 1.4;
    }
  }

  @media (max-width: 1500px) {
    padding: 20px 10px;
  }

  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
}

/*==========Casino Page CSS End==========*/

/*==========Player Reports Page CSS==========*/

.player_reports_table_wrapper {
  h2 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 10px;
    border: 2px solid $border_color;
    margin: 20px 0;
    color: $primary_color;
  }
}

.player_reports_table {
  header {
    background: transparent;
  }

  .rdt_Pagination {
    color: white;
  }

  .iDgIGr {
    border: 1px solid $border_color;
    border-radius: 8px;
  }

  .pagination_wp {
    .pagination {
      justify-content: end;
      flex-wrap: wrap;
      row-gap: 7px;

      .page-item {
        margin: 0 5px;

        .page-link {
          --size: 40px;
          background-color: transparent;
          border: 1px solid $border_color;
          border-radius: 5px;
          padding: 5px 8px;
          min-width: var(--size);
          height: var(--size);
          background-color: rgba($body_bg, 0.2);
          color: $white_color;

          &:hover {
            background-color: $primary_color;
          }

          &:disabled {
            cursor: not-allowed;
          }
        }

        &.active {
          .page-link {
            background-color: $primary_color;
          }
        }
      }
    }
  }

  .search_form {
    display: flex;
    gap: 12px;

    .clear_btn {
      position: absolute;
      top: 0;
      right: 0;
      width: var(--padding);
      height: 100%;
      background-color: transparent;
      outline: none;
      border: none;
      color: $red_color;
    }
  }
}

.pagination_wp .ant-pagination {
  display: flex;
  justify-content: flex-end;
  row-gap: 7px;
  --size: 40px;
}

.pagination_wp .ant-pagination-item a,
.pagination_wp .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.pagination_wp .ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border: 1px solid hsla(0, 0%, 100%, 0.08) !important;
  border-radius: 5px !important;
  color: #fff !important;
  height: var(--size) !important;
  min-width: var(--size);
  padding: 5px 8px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.pagination_wp .ant-pagination-item {
  display: inline-flex;
  height: var(--size) !important;
  width: var(--size) !important;
  min-width: var(--size) !important;
}

.ant-pagination-item.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination-next .ant-pagination-item-link:hover {
  border-color: #8651d6 !important;
  background-color: #8651d6 !important;
}

.pagination_wp {
  margin-top: 40px;
}

.pagination_wp .ant-pagination-item-link-icon {
  color: #fff !important;
}

span.ant-pagination-item-ellipsis {
  color: #fff !important;
}

.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon,
.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
  width: var(--size) !important;
  height: var(--size) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination-item-link {
  display: block !important;
  width: 100%;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background: transparent;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #8651d6 !important;
  color: #fff !important;
}

.daterangepicker td.in-range {
  background: #ffffff;

  &:hover {
    color: #1b1b1b;
  }
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background: #8651d6;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: #fff;
}

.daterangepicker .drp-buttons .btn {
  border-color: #fff;
  color: #fff;
}

.daterangepicker .drp-buttons .btn-primary {
  background: #fff;
  color: #1b1b1b;
}

.player_chart,
.player_reports_table {
  .sec_btn:not(.dropdown_btn, .pagination .sec_btn) {
    margin-top: 37px;

    @media (max-width: 991px) {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}

/*==========Player Reports Page CSS End==========*/

/*==========Casino Game Reports Page CSS==========*/

.datepicker_right {
  &.bets_game_type_chart {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    text-align: center;
    flex-wrap: nowrap;
  }
}

.game_reports_charts {
  .datepicker_right {
    .dropdown_list {
      max-height: 280px;
    }
  }
}

/*==========Casino Game Reports Page CSS End==========*/

.game_bets_tooltip {
  padding: 10px 20px;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }

  .game_bets_tooltip_total_bets {
    b {
      font-weight: bold;
    }
  }
}

.finance_heapchart {
  .currency_dropdown {
    .sec_btn {
      width: 100%;
      justify-content: space-between;
    }
  }
}

.invoice_form {
  .big_textarea {
    height: 220px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 0;
  }

  .date_picker_wrapper {
    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
  }
}

.invoice_wrapper {
  margin-top: 30px;
  overflow-x: auto;

  #invoice {
    overflow: hidden;
    background: url("../images/pdf-watermark.jpg") !important;
    background-size: cover !important;
    background-position: center !important;
    width: 585px;
    position: relative;
    background: #fff;
    margin: 0 auto;
  }

  .pdf_body {
    padding: 30px;
    height: auto !important;
    width: 100% !important;
  }

  span.bottom_invoices_border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 615px;
    height: 15px;
    background: #bf2231;
    display: block;
  }

  span.top_invoices_border {
    width: 615px;
    height: 15px;
    background: #bf2231;
    display: block;
  }
}

.slot_table {
  .rdt_TableHeadRow {
    min-height: 40px;

    .rdt_TableCol {
      background: $primary_color;
      color: $white_color;
    }

    .rdt_TableCol_Sortable {
      font-weight: bold;
    }
  }

  .rdt_TableCol,
  .rdt_TableCell {
    padding-left: 12px;
    padding-right: 12px;
  }

  .rdt_TableBody {
    .rdt_TableRow {
      min-height: 40px;
    }
  }

  .rdt_TableCell:has(.selected_col) {
    background: rgb(135 81 212 / 15%);
  }

  .rdt_TableCell {
    .error_msg {
      font-weight: bold;
    }
  }

  h6 {
    font-weight: 500;
    font-size: 18px;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 20px;
      height: 3px;
      background: $primary_color;
    }
  }
}

.slot_one_row_table {
  .rdt_TableHead:has(.rdt_TableCol:empty) {
    display: none;
  }

  .rdt_TableBody {
    .rdt_TableRow {
      background: $primary_color;
      font-weight: bold;
    }
  }
}

.casino_game_rounds_wp {
  .sec_btn:not(.pagination .sec_btn) {
    margin-top: 37px;

    @media (max-width: 991px) {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .rdt_Table {
    .rdt_ExpanderRow {
      pre {
        padding: 20px 30px 0px;
      }
    }
  }
}

.not_access_page {
  height: 70vh;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favorite_games {
  padding: 20px;
  padding-left: 65px;

  h5 {
    font-size: 18px;
    border-bottom: 2px solid $border_color;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  ul {
    li {
      display: flex;
      align-items: center;
      gap: 8px;
      text-transform: capitalize;
      margin-bottom: 15px;

      span {
        font-weight: 600;
        color: $primary_color;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.home_casino_token {
  .form_box_wp {
    padding: 20px;
  }
}

.accordion {
  .accordion_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba($white_color, 0.03);
    border: 2px solid $border_color;
    padding: 14px 20px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 0;
    transition: 0.3s;

    svg {
      transition: 0.3s;
    }
  }

  .accordion_content_wrap {
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.3s;

    >div {
      // overflow remover
      overflow: hidden;
    }

    .accordion_content {
      padding: 20px 20px 0 20px;
      border: 2px solid $border_color;
      border-top: 0;
      border-radius: 0 0 10px 10px;
    }
  }

  &.accordion_active {
    .accordion_title {
      border-radius: 8px 8px 0 0;

      svg {
        transform: rotate(-180deg);
      }
    }

    .accordion_content_wrap {
      grid-template-rows: 1fr;
    }
  }
}

.casino_brands_input {
  .dropdown_wp {
    .dropdown_content {
      position: unset;
      height: 0;
      margin: 0;
    }

    &.active_dropdown {
      .dropdown_content {
        height: auto;
        margin-top: 5px;
      }
    }
  }
}

.pagination {
  justify-content: end;
  margin-top: 15px;
  gap: 10px;
}

.apexcharts-legend.apexcharts-align-left.apx-legend-position-bottom {
  justify-content: center !important;
  padding: 0;
  inset: auto 0 8px 0 !important;
}

.user_permission_sec {
  .user_select_box {
    .form_box_wp {
      padding: 20px;
      margin-top: 30px;
    }

    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }

  .form-switch {
    padding-left: 3.1em;
    padding-right: 0;

    .form-check-input {
      width: 2.5em;
      height: 23px;
      box-shadow: none !important;
      cursor: pointer;

      &:checked {
        background-color: $primary_color;
        border-color: rgba($primary_color, 0.5);

        &:before {
          content: "";
        }

        &:focus {
          --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
        }
      }

      &:focus {
        --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
      }
    }
  }

  .user_permission_table {

    .rdt_Table,
    .rdt_TableHeadRow,
    .rdt_TableRow {
      background: transparent;
      border: none;
    }

    .rdt_TableCol,
    .rdt_TableCell {
      padding: 0;
    }
  }

  .uer_permission_info_box {
    ul {
      li {
        margin-bottom: 5px;

        b {
          font-weight: 500;
          opacity: 0.8;
          font-size: 15px;
          margin-right: 5px;
        }
      }
    }
  }
}

// select option css

.form__input-select {
  margin-bottom: 15px;

  &>div {
    background: #212121;
    border: 2px solid rgba(162, 162, 162, 0.2);
    outline: none !important;
    padding: 5px 15px;
    color: #ffffff;
    -webkit-appearance: none;
    border-radius: 10px;
    font-weight: 500;
  }

  .css-1dimb5e-singleValue,
  input {
    color: #fff !important;
  }

  [class*="-multiValue"] {
    border-radius: 3px;

    svg {
      color: #222;
    }
  }
}

.chart_options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 8px;
  margin-right: 100px;
  padding-bottom: 10px;
  margin-top: 15px;

  p {
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;

    @media (max-width: 575px) {
      font-size: 14px;
    }

    &.active_chart_option {
      color: #949494;
    }

    &:not(:last-child) {
      &:after {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #fff;
      }
    }
  }

  @media (max-width: 575px) {
    margin: 14px 0;
    gap: 8px;
  }
}

// 2Fa

.security_content_wrapper {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
  background: #161616;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);

  @media (max-width: 400px) {
    padding: 20px;
  }

  p {
    line-height: 1.6;
    // margin: 0;
    font-size: 16px;
  }

  .security_qr_img {
    img {
      // filter: brightness(0) invert(1);
    }
  }

  .secret_key_input {
    p {
      margin-bottom: 8px;
    }
  }

  .ca_details_input {
    display: inline-block;
    padding: 12px 20px;
    font-size: 18px;
    border: 2px solid rgb(255 255 255 / 10%);
    border-radius: 10px;
    word-break: break-all;
    margin-top: 20px;

    @media (max-width: 400px) {
      padding: 20px;
    }

    p {
      line-height: 1.5;
      margin-bottom: 5px;
    }
  }

  .security_code_input {
    margin-top: 20px;
    padding: 30px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    background: #171717;

    input[type="number"] {
      max-width: 200px;
      font-size: 24px;
      text-align: center;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    p {
      margin-bottom: 12px;
    }
  }
}

.security_code_disabled {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
  background: #161616;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);

  @media (max-width: 400px) {
    padding: 20px;
  }

  p {
    line-height: 1.6;
    margin: 0;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.verify_otp_wrapper {
  @media (max-width: 576px) {
    width: 100%;

    .verify_otp {
      width: 100%;
    }
  }

  .verify_otp {
    // max-width: 360px;
    display: flex;
    gap: 12px;
    justify-content: center;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    .sec_btn {
      flex: 0 0 auto;
    }
  }

  .verify_otp_message {
    display: inline-block;
    margin-top: 12px;
    color: red;
    margin-bottom: -15px;
    align-self: flex-start;
  }
}

// modal css

.modal-content {
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  background-color: #161616;

  .modal-header,
  .modal-footer {
    border: none;
  }

  .modal-header,
  .modal-body {
    padding: 0;
  }

  .modal-header {
    margin-bottom: 20px;
  }
}

.bonus_deduction_games_form {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;

    &:last-of-type {
      .bonus_deduction_games_form_wp {
        margin-bottom: 0;
      }
    }
  }

  .sec_btn:not(.bonus_deduction_games_form_wp .sec_btn) {
    margin-top: 18px;

    &:first-of-type {
      .bonus_deduction_games_form_wp {
        margin-bottom: 0;
      }
    }

    @media (max-width: 991px) {
      margin-top: 0;
      position: absolute;
      top: 0px;
      padding: 2px 8px;
      right: 0;
      border-radius: 5px;
    }
  }
}

.bonus_deduction_games_form_wp {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 18px;
  }

  .form_input_wp {
    flex: 1;
  }
}

.multi_select_dropdown {
  .dropdown_list {
    li {
      display: flex !important;
      align-items: center;
      justify-content: space-between;

      &.multi_select_divider {
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 0;
        margin: 5px 0;
      }

      svg {
        margin: 0;
      }
    }
  }
}

.apexcharts-legend.apexcharts-align-left.apx-legend-position-right {
  width: 170px;
}

// casino dropdown css start //
.brand_dropdown {
  .dropdown_list {
    all: unset !important;
  }
}

// casino dropdown css end //